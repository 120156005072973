<template>
  <div ref="el" class="content ">
    <header class="p-t-10">
      <img src="@/assets/image/setting.png" alt="" class="width-18 height-18 tw-absolute m-l-14" @click="drawerShow=true">
      <div class="info tw-flex tw-items-center tw-justify-center height-150 fz-20 tw-text-white">
        <img :src="info.brand_info&&info.brand_info.brand_logo" alt="" class="width-26 height-26 m-r-10 tw-rounded-full">
        智能防窜经销端
      </div>
    </header>
    <div id="menus" class="tw-flex tw-flex-wrap  tw-justify-between p-l-15 p-r-15">
      <router-link class="menusItem width48 m-b-12 tw-relative" v-for="(v,k) in menus" :key="k" tag="div" :to="v.path">
        <img :src="v.icon" alt="">
        <span class="tw-absolute fz-18 tw-text-white">{{v.name}}</span>
      </router-link>
    </div>
  </div>
  <!-- 左侧 -->
  <van-popup v-model:show="drawerShow" position="left">
    <div id="leftContent" class="tw-flex tw-flex-col tw-items-center p-t-76">
      <div id="logo" class="m-b-10">
        <img :src="info.brand_info&&info.brand_info.brand_logo" alt="" class="width-60 height-60 tw-rounded-full">
      </div>
      <p class="fz-16 font-medium m-b-45">{{info.brand_info.brand_name}}</p>
      <div id="leftMenus" class="width100 p-l-16 p-r-16">
        <router-link class="font-Medium block m-b-36 tw-relative tw-flex tw-items-center tw-justify-between" v-for="(v,k) in leftMenus" :key="k" tag="div" :to="v.path">
          <div class="tw-flex tw-items-center">
            <img :src="v.icon" alt="" class="width-24 height-24 m-r-10">
          <span class="fz-18 ">{{v.name}}</span>
          </div>
          <van-icon name="arrow" class="fz-12" />
        </router-link>
      </div>
      <router-link id="loginOut" to="/login">
        <img src="@/assets/image/loginOut_icon.png" alt="" class="width-18 height-18"> 退出登录
      </router-link>
    </div>
  </van-popup>
</template>
<script>
import { computed, ref } from '@vue/reactivity'
import store from '@/store'
export default {
  setup() {
  const info=computed({
      get(){
        return  store.state.user.info
      }
    })
    const drawerShow = ref(false)
    //菜单
    const menus = [
      // {
      //   path: '/inWarehouse/index',
      //   icon: require('@/assets/image/in.png'),
      //   name: "入库扫码"
      // },
      {
        path: '/outWarehouse/index',
        icon: require('@/assets/image/out.png'),
        name: "出库扫码"
      },
      {
        path: '/returnGoods/index',
        icon: require('@/assets/image/return.png'),
        name: "退货扫码"
      },
      // {
      //   path: '/relation/index',
      //   icon: require('@/assets/image/associate.png'),
      //   name: "数据关联"
      // },
      // {
      //   path: '/replace/index',
      //   icon: require('@/assets/image/replace.png'),
      //   name: "标签替换"
      // },
      // {
      //   path: '/track/index',
      //   icon: require('@/assets/image/search.png'),
      //   name: "追踪查询"
      // }
    ]
    // 左侧菜单
    const leftMenus = [
      // {
      //   path: '/inWarehouse/order',
      //   icon: require('@/assets/image/in_icon.png'),
      //   name: "入库单"
      // },
      {
        path: '/outWarehouse/order',
        icon: require('@/assets/image/out_icon.png'),
        name: "出库单"
      },
      {
        path: '/returnGoods/order',
        icon: require('@/assets/image/return_icon.png'),
        name: "退货单"
      },
    ]
    return {
      drawerShow,
      menus,
      leftMenus,
      info
    }
  }
}
</script>
<style lang="scss" scoped>
.content {
  background-image: url("../../assets/image/background.png");
  background-size: cover;
  width: 100vw;
  height: 100vh;
  #menus {
    span {
      width: 2em;
      right: 25px;
      top: 50%;
      transform: translateY(-50%);
      font-weight: 600;
    }
  }
}
#leftContent {
  background-color: #fff;
  width: 70vw;
  height: 100vh;
  #loginOut{
    position: absolute;
    bottom: 0;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F8F8F8;
    width: 100%;
    img{
      display:inline-block;
      margin-right: 10px;
    }
  }
}
</style>
